<template>
  <div>
    <div class="home_img">
      <div class="home_top">
        <div class="home_name">About Amber</div>
        <div class="come_name">关于琥珀</div>
      </div>
    </div>
    <div class="guanyu">
      <div class="guanyu_right">
        <div class="amber">
          <div class="About">About Amber</div>
          <div class="hupo">关于琥珀</div>
        </div>
        <div class="xian"></div>
        <div class="amber_comment">
琥珀数字视觉艺术版权运营拍卖平台，简称“琥珀”。基于人工智能、区块链、XR、全息影像和游戏引擎等技术，为艺术家「视觉版权」提供分布式存储、版权确权、上链、拍卖、运营和版权保护等一站式服务。<br/><br/>

琥珀致力于“科技+艺术”的融合共生，创新打造艺术家自我展示和表达的舞台，助力艺术家成长；从根本上改变数字视觉艺术的创作、销售和收藏形式，构建全新的「视觉版权」供需生态。<br/><br/>

琥珀平台上拍卖的每个「视觉版权」，均由国家版权机构确权认证，真实可信；同时上链存证，全球限量发售一份，保证其唯一性和稀缺性。<br/><br/>

琥珀平台愿与合作伙伴携手，打造新一代视觉版权服务平台事业，以数字经济赋能实体经济发展。目前，琥珀平台已与国内一流基金会、拍卖行、机构院校、媒体杂志、经纪公司等建立了深度战略合作关系。
        </div>
        <!-- <div class="amber_comment">
            琥珀平台上线拍卖的每一个短视频版权产品，都经由国家版权机构确权认证，真实可信；同时经由区块链技术上链存证，每个短视频版权全球限量发售一份，保证唯一性和稀缺性。

        </div> -->
      </div>
    </div>
    <div class="comment">
      <div class="hegui">
        <div class="befone">
          <div class="befone_name">合规优先</div>
          <div class="befone_comment">琥珀具备全牌照运营资质，金融级资金托管体系，业务经营合法合规。</div>
          <div class="befone_xian"></div>
        </div>
        <div class="image_befone">
          <img src="@/img/image_2.2.png" alt="">
        </div>
      </div>
    </div>
    <div class="comment">
      <div class="hegui">
        <div class="image_befone">
          <img src="@/img/image_2.3.png" alt="">
        </div>
        <div class="befone">
          <div class="befone_name">技术赋能</div>
          <div class="befone_comment">琥珀以自研的数字水印加密、视频搜索技术以及区块链分布式存储技术为正版短
视频提供全面的版权保护。</div>
          <div class="befone_xian"></div>
        </div>
        
      </div>
    </div>
    <div class="comment2">
      <div class="hegui">
        
        <div class="befone">
          <div class="befone_name">生态合作</div>
          <div class="befone_comment">琥珀与国内一流基金会、拍卖行、机构院校、媒体杂志、经纪公司等建立深度战略合作，
共建共享数字经济新生态。</div>
          <div class="befone_xian"></div>
        </div>
        <div class="image_befone">
          <img src="@/img/image_2.4.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.home_img{
  width: 100%;
  margin: 0 auto;
  height: 52.33rem;
  background: url('@/img/image_2.png') no-repeat;
  background-size: 100% 100%;
  background-repeat:no-repeat;
  overflow: hidden;
}
.home_top{
  margin: 0 auto;
  margin-top: 14rem;
  width: 100rem;
}
.home_name{
  font-size: 5.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 8rem;
}
.come_name{
  font-size: 4.33rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #2A2E2E;
  line-height: 6.08rem;
  margin-top: 11px;
}
.guanyu{
  background: url('@/img/image_2.1.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  /* height: 50.17rem; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
.guanyu_right{
  width: 130rem;
}
.amber{
  margin-top: 3.67rem;
}
.About{
  font-size: 2.33rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2E2E;
line-height: 3.33rem;
}
.hupo{
  font-size: 2.33rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #2A2E2E;
line-height: 3.33rem;
}
.xian{
  width: 130rem;
height: 1px;
background: #979797;
margin-top: 24px;
}
.amber_comment{
  margin-top: 25px;
  width: 100rem;
  /* height: 10rem; */
  font-size: 2rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 3.33rem;
}
.comment{
  width: 100%;
  margin: 0 auto;
}
.hegui{
  display: flex;
  justify-content: space-between;
  width: 100rem;
  /* height: 23.25rem; */
  margin: 0 auto;
  margin-top: 4rem;
}
.image_befone{
  width: 18.42rem;
  height: 23.25rem;
}
.image_befone img{
  width: 100%;
  height: 100%;
}
.befone{
width: 70.83rem;
}
.befone_name{
  margin-top:3rem;
  font-size: 2.67rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2A2E2E;
  line-height: 3.75rem;
}
.befone_comment{
  height: 5.5rem;
  margin-top: 1.5rem;
  font-size: 2rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 2.75rem;
}
.befone_xian{
  width: 100%;
  height: 0.08rem;
  margin-top: 9.58rem;
  background:  #979797;
}
.comment2{
  width: 100%;
  height: 41.67rem;
  margin: 0 auto;
  background: url('@/img/image_2.5.png') no-repeat;
  background-size: 100% 100%;
  margin-top: 6.42rem;
}
</style>