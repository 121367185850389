import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App)
document.title='清水博客'
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});
app.use(router)
app.mount('#app')
