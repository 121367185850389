<template>
  <div class="home">
    <div class="home_img">
      <div class="home_top">
        <div class="home_name">清水博客</div>
        
      </div>
    </div>
     
   </div>
</template>

<script>
export default {
  methods: {
    ysbj() {
      location.href='http://www.haoqingshui.com/'
    },
    hezuo(e) {
      location.href=e
    }
  }
}
</script>

<style scoped>
.home{
  overflow: hidden;

}
.home_img{
  width: 100%;
  margin: 0 auto;
  height: 52.33rem;
  background: url('@/img/snow.jpg') no-repeat;
  background-size: 100% 100%;
  background-repeat:no-repeat;
  overflow: hidden;
}
.home_top{
  margin: 0 auto;
  margin-top: 15.83rem;
  width: 100rem;
}
.home_name{
  font-size: 4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 5.58rem;
}
.come_name{
  width: 41.17rem;
  font-size: 24px;
  font-size: 3.5rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #21cbf2;
  line-height: 4.92rem;
  margin-top: 1.67rem;
}
.guanyu{
  width: 100%;
  margin: 0 auto;
  height: 72.83rem;
  background: url('@/img/image_1.0.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.guanyu_hupo{
  width: 100rem;

  margin: 0 auto;
  font-size: 2.33rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2A2E2E;
  line-height: 3.33rem;
  margin-top: 3.67rem;

}
.guanyu_hupo>span{
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #2A2E2E;
line-height: 3.33rem;
}
.guanyu_babar{
  
}
.babar_top{
  width: 100rem;
  height: 30.5rem;
  margin: 0 auto;
  margin-top: 19px;
  background: url('@/img/003.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.bei_name{
  font-size: 3.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 4.67rem;
  margin-top: 5.33rem;
  margin-left: 4.33rem;
}
.bei_comment{
  font-size: 2.17rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3.08rem;
  margin-top: 0.92rem;
  margin-left: 4.33rem;
}

.babar_top>img{
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}
.hover_xiang{
  margin-top: 9.17rem;
  margin-left: 4.33rem;
  font-size: 2rem;
  color: #FFFFFF;
  width: 13.67rem;
  height: 3rem;
  background: #ff574c;
  border-radius: 1.5rem;
  text-align: center;
  line-height: 3rem;
}
.hover_xiang:hover{
  cursor: pointer;
}
.babar_bottom{
  width: 100rem;
  height: 27.83rem;
  background: #fff;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
}
.babar_hu{
  width: 27rem;
  text-align: center;
  margin-top: 3.67rem;
}
.babar_img{
  width: 10.83rem;
  height: 10.83rem;
  margin: 0 auto;
}
.babar_hu img{
  width: 100%;
  height: 100%;
}
.babar_name{
  font-size: 1.83rem;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #2A2E2E;
  line-height: 2.5rem;
  margin-top: 1.75rem;
}
.babar_comment{
  font-size: 1.5rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 2.08rem;
  margin-top: 0.92rem;
}
.zhishi{
  width: 100%;
  margin: 0 auto;
  height: 54.17rem;
  background: rgba(242, 243, 245, .5);
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}
.zhishi_right{
  width: 129rem;
  position: relative
}
.zhishi_img{
  width: 70.17rem;
  height: 45.83rem;
  position: absolute;
  right: 3.5rem;
  top: 1.95rem;
}
.zhishi_img>img{
  width: 100%;
  height: 100%;
}
.zhishi_title{
  margin-top: 10.25rem;
  font-size: 3.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 4.67rem;
}
.zhishi_name{
  margin-top: 0.92rem;
  font-size: 1.83rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #2A2E2E;
  line-height: 2.5rem;
}
.xian{
  height: 0.08rem;
  background:#999999;
  margin-top: 3.42rem;
}
.jiaxaing{
  margin-top: 1.08rem;
  font-size: 1.83rem;
}
.jiaxaing>div{
  display: flex;
  align-items: center;
  margin-top: 2rem;

}
.jiaxaing img{
  width: 1.83rem;
  height: 1.83rem;
  margin-right: 0.58rem;
}
.xiangqing{
  width: 13.67rem;
  height: 3rem;
  background: #ff574c;
  border-radius: 1.5rem;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  line-height: 3rem;
  margin-top: 4.33REM;
}
.zhanlue{
  width: 100%;
  
  margin: 0 auto;
  overflow: hidden;
}
.zhanlue_name{
  margin-top: 3.83rem;
  text-align: center;
  font-size: 3.33rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 4.67rem;
}
.hezuo{
  width: 93rem;
  /* height: 20.83rem; */
  margin-top: 1.53rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 2rem;
  /* margin-left: 2rem; */
}
.hezuo>div{
  width: 0.2rem;
height: 4.54rem;
background: #BCB9B9;
margin: 0 3.25rem;
cursor: pointer;
 margin-top: 2rem;
}
.hezuo>img{
   margin-top: 2rem;
  height: 6.67rem;
}
.hezuo>div.hezuo_1{
  margin: 2rem 3.33rem 0 3rem;
}
.hezuo>div.hezuo_2{
  margin: 2rem 3.25rem 0 3rem;
}
.hezuo>div.hezuo_3{
  margin: 2rem 2.67rem 0 2.17rem;
}
.hezuo>div.hezuo_5{
  margin: 2rem 3.25rem 0 3rem;
}
.hezuo>div.hezuo_6{
  margin: 2rem 3.25rem 0 4.33rem;
}
.hezuo>div.hezuo_7{
  margin: 2rem 5rem 0 3.7rem;
}
.xiangqing:hover{
  cursor: pointer;
}

</style>
