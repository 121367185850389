import { createRouter, createWebHistory } from 'vue-router'
import home from '@/views/home.vue';
import qingshui from '@/views/qingshui.vue';
import about from '@/views/about.vue';
import dotai from '@/views/dotai.vue';
import details from '@/views/details.vue';
import details2 from '@/views/details2.vue';
import copyright from '@/views/copyright.vue';

const routes = [
  {path: '/',name: 'home',component: home},
  {path: '/qingshui',name: 'qingshui',component: qingshui},
  {path: '/about',name: 'about',component: about},
  {path: '/dotai',name: 'dotai',component: dotai},
  {path: '/details',name: 'details',component: details},
  {path: '/details2',name: 'details2',component: details2},
  {path: '/copyright',name: 'copyright',component: copyright},
]

const router = createRouter({
  //history:createWebHashHistory(),
  history:createWebHistory(),
  routes,
  
})

export default router