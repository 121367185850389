<template>
  <div class="solicit">
    <div class="solicit_top">
      <div class="zhao">
        <div class="name">ABOUT US</div>
        <div class="jia">关于我们</div>
        <div class="zhi">关于我们</div>
        <div class="zhao_comment">清水软件</div>
      </div>
    </div>
    <div class="solicit_bottom">
      <div class="scolcit_comment">
        <div class="boder_left">
          <div class="border_num">
            <div class="jishu_name">清水</div>
            <div class="yaoqiu">
              <div class="yaoqiu_name">一直致力于软件研究</div>
              <div class="yaoqiu_comment">为你能找到好用的软件而努力的人； </div>

<!--          <div class="yaoqiu_comment">02. 主导技术架构设计,+模块划分和开发，推动解决业务和技术问题；</div>
              <div class="yaoqiu_comment">03. 主导和执行服务器端核心功能开发、问题排查和修复、性能优化；</div>
              <div class="yaoqiu_comment">04. 热衷于结合项目研究新技术、新场景的落地。</div>
             -->
            </div>

          
          </div>
           
          <br>
          <br>
          <br>

        </div>
        <div class="boder_right">
          <div class="heng_xian"></div>
          <div class="dizhi">
            <div class="zhuzhi">
              <img src="@/img/image_3.2.png" alt="">
              <div class="zhuzhi_name">北京</div> 
            </div>
            <div class="zhuzhi">
              <img src="@/img/image_3.3.png" alt="">
              <div class="zhuzhi_name">hansteve@126.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.solicit{
  width: 100%;
  margin: 0 auto;
}
.solicit_top{
  width: 100%;
  height: 52.33rem;
  background: url('@/img/002.png') no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  overflow: hidden;
}
.zhao{
  width: 100rem;
  margin: 0 auto;
  margin-top: 14rem;
}
.name{
  font-size: 5.75rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 8rem;
}
.jia{
  font-size: 4.33rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #FFFFFF;
line-height: 6.08rem;
  margin-top: 2.67rem;
}
.zhi{
  font-size: 3rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: 4.17rem;
  margin-top: 2.67rem;

}
.zhao_comment{
  font-size: 2rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #FFFFFF;
line-height: 2.75rem;
margin-top: 1.98rem;
}
.solicit_bottom{
  width: 100%;
  /* height: 90.08rem; */
  margin: 0 auto;
  /* background: red; */
  
  margin-top: 7rem;
  background: url('@/img/image_3.1.png') no-repeat;
  background-size: 100% 100%;
}
.scolcit_comment{
  width: 100rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

}
.boder_right{
  display: flex;
}
.heng_xian{
  width: 0.08rem;
height: 19.67rem;
  background: #999999;
  margin-right: 2.5rem;
}
.zhuzhi{
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
}

.zhuzhi>img{
  width: 1.17rem;
  height: 1.5rem;
  margin-top: .6rem;
}
.zhuzhi:nth-child(2)>img{
  width: 1.33rem;
  height: 1rem;
}
.zhuzhi_name{
  width: 18.5rem;
  font-size: 1.83rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 2.5rem;
  margin-left: 0.58rem;
}
.boder_left{
  width: 68rem;
  /* height: 80rem; */
  /* padding-bottom: 20px; */
  /* overflow-x: scroll;
  overflow: hidden;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;  */
  
}
.boder_left::-webkit-scrollbar{
  display: none;
}
.jishu_name{
  font-size: 3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #21cbf2;
  /* line-height: 4.17rem; */
}
.yaoqiu{
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #5F6464;
  font-size: 1.33rem;
  line-height: 2rem;
  margin-top: 2rem;
}
.border_num>.yaoqiu:nth-child(2){
  margin-top: 60px;
}
.yaoqiu_name{
  color: #21cbf2;
}
.yaoqiu_comment{
  margin-top: 10px;
}
.border_num{
  margin-top: 4rem;
}
.border_num:nth-child(1){
  margin-top: 0rem;
}
</style>