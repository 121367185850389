<template>
  <div class="dotai">
    <div class="dotai_top">
      <div class="huhpo">
        <div class="huhpo_name"> News</div>
        <div class="huhpo_dotai">动态</div>
      </div>
    </div>
    <div class="zhen">
      <div class="zhenxing">
        <div class="zhenxing_left">
          <h1 @click="$router.push('/details')">清水进销存</h1>
          <div class="zhenxing_name">清水进销存发布版本1.0</div>
          <div class="zhenxing_comment">
            清水进销存后台java开发。前端Vue  前后端分离模式。
          </div>
          <div class="More">
            <div>2022.08.16</div>
            <div class="more_poin" @click="$router.push('/details')">More</div>
          </div>
        </div>
        <div class="top_image" @click="$router.push('/details')">
          <img src="@/img/003.png" alt="">
        </div>
      </div>
    </div>
    <div class="zhen">
      <div class="zhenxing">
        <div class="zhenxing_left">
          <h1 @click="$router.push('/details')">清水CRM</h1>
          <div class="zhenxing_name">清水CRM基础版本</div>
          <div class="zhenxing_comment">
            CRM基础版本 ，后台java开发。前端Vue  前后端分离模式。
          </div>
          <div class="More">
            <div>2022.09.10</div>
            <div class="more_poin" @click="yishu">More</div>
          </div>
        </div>
        <div class="top_image" @click="yishu">
          <img src="@/img/004.png" alt="">
        </div>
      </div>
    </div>
    <!-- <div class="list">
      <div>
        <div class="list_name" @click="$router.push('/details')">知识产权助力乡村振兴</div>
        <div class="list_comment">中国国际文化交流基金会品牌联合公益项目</div>
        <div class="list_More">
            <div>2022.06.12</div>
            <div class="more_poin" @click="$router.push('/details')">More</div>
          </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    yishu() {
      location.href='/details2'
    }
  }
}
</script>

<style scoped>
.dotai{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  background: #F2F3F5;
  padding-bottom: 20px;
}
.dotai_top{
  width: 100%;
  height: 52.33rem;
  background: url(@/img/001.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.huhpo{
  width: 100rem;
  margin: 0 auto;
  color: #fff;
  margin-top: 14rem;
}
.huhpo_name{
  font-size: 5.75rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 8rem;
}
.huhpo_dotai{
  font-size: 4.33rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #FFFFFF;
line-height: 6.08rem;
}
.zhen{
  /* margin-top: 9.67rem; */
  width: 100%;
  margin: 0 auto;
}
.zhenxing{
  width: 100rem;
  height: 33.33rem;
  margin: 0 auto;
  margin-top: 9.67rem;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
}
.top_image{
  width: 49.17rem;
  height: 33.33rem;
}
.top_image>img{
  width: 100%;
  height: 100%;
}
.zhenxing_left{
  width: 50.83rem;
  background: #fff;
  overflow: flex;
  margin-left: 1.92rem;
  overflow: hidden;
}
.xian{
  width: 100%;
  height: 0.08rem;
  margin-top: 1.5rem;
  background:#979797;
}
.zhenxing_name{
  font-size: 1.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 2.08rem;
  /* margin-top: 44px; */
}
.zhenxing_comment{
  width: 45.25rem;
  height: 8.83rem;
  font-size: 1.17rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 2rem;
  display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 5; word-break: break-all; overflow: hidden;
  margin-top: 1.92rem;
}
.More{
  display: flex;
  justify-content: space-between;
  width: 45.25rem;
  font-size: 1.17rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5F6464;
  line-height: 1.67rem;
  margin-top: 10.58rem;
}
.list{
  width: 100rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.list>div{
  width: 45.33rem;
height: 16.67rem;
background: #FFFFFF;
  margin-top: 1.67rem;
  padding: 0 1.92rem;
}
.list_name{
  font-size: 2.33rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #2A2E2E;
line-height: 3.33rem;
  margin-top: 2.42rem;
}
.list_comment{
  font-size: 1.5rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #2A2E2E;
line-height: 2.08rem;
  margin-top: 0.5rem;
}
.list_More{
  display: flex;
  justify-content: space-between;
  margin-top: 4.17rem;
  font-size: 1.17rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5F6464;
  line-height: 1.67rem;
}
.more_poin:hover{
 cursor: pointer;
 color: blue;
}
</style>
