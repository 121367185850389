<template>
  <div class="details">
    <div class="detail">
      <div>首页 > 动态 > 文章详情 </div>
    </div>
    <div class="comment">
      <div class="comment_name">
        <div class="name">清水进销存</div>
        <div class="time">2022.08.12</div>
      </div>
      <div class="comment_mord">
		   
		  云服务，简单实用。</div>
      <div class="xian"></div>
      <div class="image_ig">
        <img src="@/img/003.png" alt="">
      </div>
      <div class="comment_comm">
		  * 1 后端使用技术<br>
		      * 1.1 SpringBoot2<br>
		      * 1.2 mybatis、MyBatis-Plus<br>
		      * 1.3 SpringSecurity<br>
		      * 1.5 Druid<br>
		      * 1.6 Slf4j<br>
		      * 1.7 Fastjson<br>
		      * 1.8 JWT<br>
		      * 1.9 Redis<br>
		      * 1.10 Quartz<br>
		      * 1.11 Mysql<br>
		      * 1.12 swagger<br>
		      * 1.13 WxJava<br>
		      * 1.14 Lombok<br>
		      * 1.15 Hutool<br>
		          <br>
		  * 前端使用技术<br>
		      * 2.1 Vue 全家桶<br>
		      * 2.2 Element<br>
		      * 2.3 uniapp<br><br>

	   试用地址：http://jxc.m300.com.cn <br/>
      
       试用用户名：qingshui <br>
       试用密码：123456 <br>
		  
		   <a href="https://work.weixin.qq.com/kfid/kfc4b4446757017e669">购买授权请咨询客服</a>
		   <img src="@/img/qrcode.png" alt="" style="image_ig">
		   <br/><br/>
		   
		   购买授权： 1980 不限时间。 提供源码 ， 持续更新。
		   
		   <br/>
		   联系：qq: 1502539413
		   <br/>
		  </div>
    </div>
  </div>
</template>




<style scoped>
.details{
  margin-bottom: 2rem;
}
.detail{
  width: 100%;
  background: #F2F3F5;
  opacity: 0.9;
  margin: 0 auto;
  height: 6rem;
}
.detail>div{
  width: 100rem;
  font-size: 1.5rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  margin: 0 auto;
  line-height: 6rem;

}
.comment{
  width: 100rem;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 3.42rem;
}
.comment_name{
  
  display: flex;
  justify-content: space-between;
}
.name{
  font-size: 3.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 5.25rem;
}
.time{
  font-size: 1.5rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #5F6464;
line-height: 2.08rem;
}
.comment_mord{
  font-size: 3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2A2E2E;
  line-height: 4.17rem;
  margin-top: 0.67rem;
}
.xian{
  width: 100rem;
  height: 0.08rem;
  background: #979797;
  margin-top: 1.92rem;
}
.image_ig{
  width: 100rem;
  height: 43.33rem;
  margin: 0 auto;
  margin-top: 3.rem;

}
.image_ig>img{
  width: 100%;
  height: 100%;
}
.comment_comm{
  width: 100rem;
  font-size: 1.97rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #5F6464;
  line-height: 3rem;
  margin: 0 auto;
  margin-top: 23px;
}
</style>


