<template>
  <div class="copyright">
    <!-- <img src="@/img/1.png" alt=""> -->
    <div class="shijue">
      <img src="@/img/copyright/shijue.png" alt="">
    </div>
    <div class="copyright_banquan">
      <div class="copyright_title"> 视觉版权</div>
      <div class="shijue_comment">以短视频为载体，经由官方确权，通过拍卖确价，体现的是数字视觉艺术的核心价值。</div>
      <div class="list">
        <div class="youdian">
          <div class="youdian_img">
              <img src="@/img/copyright/qiu.png" alt="" />
          </div>
          
          <span>最佳载体</span>
          <div class="title">短视频是当前信息传播的最高形态。</div>
        </div>
        
        <!-- <div class="list_xian"></div> -->
        <div class="youdian">
          <div class="youdian_img">
              <img src="@/img/copyright/san.png" alt="" />
          </div>
          
          <span>受众面广</span>
          <div class="title">超强的互动性让艺术思想触达到更多维度的受众。</div>
        </div>
        
        <!-- <div class="list_xian"></div> -->
        <div class="youdian">
          <div class="youdian_img">
            <img src="@/img/copyright/so.png" alt="" />
          </div>
          
          <span>合法合规</span>
          <div class="title">经由国家版权机构确权认证，真实可信。</div>
        </div>
        
        <!-- <div class="list_xian"></div> -->
        <div class="youdian">
          <div class="youdian_img">
              <img src="@/img/copyright/dain.png" alt="" />
          </div>
          
          <span>唯一稀缺</span>
          <div class="title">全球发售一份，上链存证，保证稀缺性。</div>
        </div>
        
      </div>
      

      </div>
      <div class="keyi">
        <div class="keyi_2">
          <div class="zuo">
            <div class="copyright_title"> 
              <img src="@/img/copyright/copyright_title1.png" alt="" />
            </div>
            <div class="shijue_comment">通过新兴科技手段完成的艺术创作，集合了声、光、电、VR/XR技术、全息影像、实时渲染、虚拟场景等，既可通过互联网传播又可在实体空间展示，带给艺术家全新的创作体验。</div>
          </div>
          <div class="you">
            <img src="@/img/copyright/you.png" alt="" />
          </div>
        </div>
        
      </div>
      <div class="zuo2">
        <div class="copyright_title"> 
          <div class="dsakbdj">
              <img src="@/img/copyright/copyright_title.png" alt="" />
          </div>
          
        </div>
        <div class="shijue_comment">区块链技术的出现，实现了对数字作品的唯一凭证，使数字视觉艺术具备了购买价值和收藏价值，全球先锋艺术家们开始涉足数字视觉艺术创作。</div>
      
      </div>
     <div class="copyright_ban">
      <div class="shijian">
   
      <div class="shijian_left">
        
          <div class="laber_1">
            <div class="label_top">
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_1.png" alt="">
              </div>
              <div class="asn">
                <span>2021年9月</span>
                <div class="laber_1_xian"></div>
              </div>
            </div>
            <div class="laber_comment">
              <div class="laber_image">
                <img src="@/img/copyright/label_1.1.png" alt="">
              </div>
              <div class="comment_label">
                王家卫的电影《花样年华：一刹那》91秒未公开片段，在苏富比成交价428.4万港币。
              </div>
            </div>
          </div>
          <div class="laber_2">
            <div class="label_top">
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_2.png" alt="">
              </div>
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_2.1.png" alt="">
              </div>
              <div class="asn">
                <span>2022年5月</span>
                <div class="laber_1_xian"></div>
              </div>
            </div>
            <div class="laber_comment">
              <div class="laber_image">
                <img src="@/img/copyright/label_2.2.png" alt="">
              </div>
              <div class="comment_label">
                美国流行偶像麦当娜和数字艺术家联手推出
              </div>
            </div>
          </div>
          <div class="laber_3">
            <div class="label_top">
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_3.png" alt="">
              </div>
              <div class="asn">
                <span>2022年8月</span>
                <div class="laber_1_xian"></div>
              </div>
            </div>
            <div class="laber_comment">
              
              <div class="comment_label">
                <div class="comment_con">琥珀数字视觉艺术APP上线</div>
                琥珀「视觉版权」，以时间为轴，封存人类创作的每个瞬间；将这些不可复现的“时空价值”艺术化、资产化，在区块链的世界中，永久保存，有序流转。
              </div>
            </div>
          </div>
          <div class="banyuan">
            <img src="@/img/copyright/banyuan.png" alt="">
          </div>
      </div>
      <div class="trangle">
        <div class="sanjiao"></div>
        <div class="trangle_xian"></div>
      </div>
      <div  class="shijian_right">
        <div class="laber_4">
            <div class="label_top">
              <div class="asn">
                <span>2021年5月</span>
                <div class="laber_1_xian"></div>
              </div>
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_4.png" alt="">
              </div>
              
            </div>
            <div class="laber_comment">
              <div class="laber_image">
                <img src="@/img/copyright/label_4_1.png" alt="">
              </div>
              <div class="comment_label">
                《查理咬我手指》在线上拍卖会以近76.1万美元的价格成交。
              </div>
            </div>
          </div>
          <div class="laber_5">
            <div class="label_top">
              <div class="asn">
                <span>2022年4月</span>
                <div class="laber_1_xian"></div>
              </div>
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_5.png" alt="">
              </div>
            </div>
            <div class="laber_comment">
              <div class="laber_image">
                <img src="@/img/copyright/label_5.1.png" alt="">
              </div>
              <div class="comment_label">
                青年画家贾蔼力的作品《风之旅》和《前程》，在苏富比拍卖会上以327.6万和176.4万港币成交。
              </div>
            </div>
          </div>
          
          <div class="laber_6">
            <div class="label_top">
              <div class="asn">
                <span>2022年7月</span>
                <div class="laber_1_xian"></div>
              </div>
              <div class="laber_1_imag">
                <img src="@/img/copyright/label_6.png" alt="">
              </div>
            </div>
            <div class="laber_comment">
              <div class="laber_image">
                <img src="@/img/copyright/label_6_1.png" alt="">
              </div>
              <div class="comment_label">
                巴塞罗那俱乐部与BCN Visuals联合推出作品《In A Way Immortal》，在苏富比拍卖行以69.3万美元成交。
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
    
  </div>
</template>
<style scoped>
.copyright{
  background: #F9FAFD;
  padding-bottom: 2rem;
}
.copyright>img{
  width: 100%;
  /* height: ; */
}
.shijue{
  width: 100%;
  margin: 0 auto;
  height: 51.67rem;
}
.shijue>img{
  width: 100%;
  height: 100%;
}
.copyright_ban{
  width: 104.83rem;
  margin: 0 auto;
}
.copyright_title{
  font-size: 2.67rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2E2E;
  line-height: 3.75rem;
  margin-top: 3rem;
  text-align: center;
}
.shijue_comment{
  width: 104.5rem;
  font-size: 2rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #2A2E2E;
  line-height: 3.33rem;
  margin: 0 auto;
  margin-top: 2.93rem;
}
.list {
   width: 104.5rem;
  margin: 0 auto;
  margin-top: 4.67rem;
  display: flex;
  /* overflow: hidden; */
  justify-content: space-between;
}
.youdian {
  margin-top: 1.92rem;
  width: 22.92rem;
  height: 29.75rem;
  background: #FFFFFF;
  /* border: .1rem solid   rgba(0,0,0,0.5); */
  box-shadow: 0rem 0.08rem 0.18rem 0rem rgba(0,0,0,0.5);
  border-radius: 2.42rem;
}
.youdian_img{
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 3.42rem;
}
.youdian_img>img{
  width: 100%;
  height: 100%;
}
.youdian > span {
  font-size: 2.67rem;
  display: block;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2E2E;
  line-height: 3.75rem;
  text-align: center;
  margin-top: 2.75rem;
}
.title {
  margin: 0 auto;
  margin-top: 3.17rem;
  width: 19.5rem;
  font-size: 2rem;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #2A2E2E;
  line-height: 3.33rem;
  margin-top: 2.42rem;
}
.copyright_banquan{
  width: 100%;
  overflow: hidden;
  padding-bottom: 5rem;
  background: red;
  background: url("@/img/copyright/copyright_bei.png");
  background-size: 100% 100%;
}
.trangle_xian{
  width: 0.17rem;
  height: 183.33rem;
  background: #FF8D2C;
  margin: 0 auto;
  margin-bottom:20rem;
}
.shijian{
  display: flex;
  justify-content: space-between;
}
.shijian_left,.shijian_right{
  position: relative;
  width: 50%;
  margin-bottom: 0rem;
}
.laber_1{
  position: absolute;
  top: 36.42rem;
  right: 2.7rem;

}
.laber_2{
  position: absolute;
  top: 90.25rem;
  right: 2.7rem;
}
.laber_3{
  position: absolute;
  top: 147.08rem;
  right: 2.7rem;
}
.label_top{
  /* width: 10rem;
  height: 10rem; */
  margin-top: 1rem;
  margin-left: 3rem;
  display: flex;
  align-items: center;
}
.asn{
 margin-top: -3rem;
 margin-left: 2rem;
 
}
.banyuan{
  width: 27.83rem;
  height: 27.83rem;
  position: absolute;
  bottom: 13rem;
  left: -15.42rem;
}
.banyuan>img{
  width: 100%;
  height: 100%;
}
.asn>span{
  display: block;
  text-align: center;
  font-size: 2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2A2E2E;
  line-height: 2.75rem;
  margin-bottom: 1rem;
}
.laber_1_xian{
  width: 27.33rem;
  /* height: 0.25rem; */
 
  border-bottom: 0.25rem dashed #FF8D2C;
}
.laber_1_imag{
  width: 11.83rem;
  display: flex;
  height: 11.83rem;
}
.laber_1_imag>img{
  width: 100%;
  height: 100%;
  margin-left: 3rem;
}
.laber_2 .laber_1_imag{
  margin-left: -1rem;
}
.laber_2 .laber_1_imag>img{
  margin-left: -1rem;
}
.laber_2 .asn{
 margin-top: -3rem;
 margin-left: -1rem;
}
.laber_comment{
  /* width: 39.92rem; */
  background: #FFFFFF;
  box-shadow: 0rem 0.17rem 0.33rem 0rem rgba(0,0,0,0.5000);
  overflow: hidden;
  /* padding-bottom: 1rem; */
  position: absolute;
  display: flex;
  padding: 1.83rem;
  align-content: center;
  align-items: center;
}
.laber_1 .asn,.laber_2 .asn,.laber_3 .asn{
  margin-right: -3.5rem;
}
.laber_1 .laber_comment,.laber_2 .laber_comment,.laber_3 .laber_comment{
  
  right: 2rem;
  margin-top: 1rem;
}

.laber_4 .laber_comment,.laber_5 .laber_comment,.laber_6 .laber_comment{
  
  left: 2rem;
  margin-top: 1rem;
}
.laber_image{
  width: 20.92rem;
  height: 15.75rem;
  margin: 0 auto;
  
}
.laber_image>img{
  width: 100%;
  height: 100%;
}
.comment_label{
  width: 20.58rem;
font-size: 2rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #2A2E2E;
line-height: 3.33rem;
margin-left: 1.17rem;
  
}
.laber_3 .laber_comment{
  width: 47.25rem;
}
.laber_3  .comment_label{
  width: 28.67rem;
  margin-left: 15.75rem;
}
.sanjiao{
width: 0px;
height: 0px;
border: 1rem solid #000;
border-bottom-color: #FF8D2C;
border-top-color: transparent;
border-left-color: transparent;
border-right-color: transparent;
margin-left: -1rem;
margin: 0 auto;
margin-top: 1rem;
/* margin-top: -1rem; */
}
.laber_4{
  position: absolute;
  top: 10.42rem;
  left: 2.7rem;
}
.laber_4 .laber_1_imag>img{
  margin-left: -1rem;
}
.laber_4 .asn{
 margin-left:-6.5rem;
}
.laber_5{
  position: absolute;
  top: 62.25rem;
  left: 2.7rem;
}
.laber_5 .laber_1_imag>img{
  margin-left: -1rem;
}
.laber_5 .asn{
 margin-left:-6.5rem;
}
.laber_6{
  position: absolute;
  top: 116.67rem;
  left: 2.7rem;
}
.laber_6 .laber_1_imag>img{
  margin-left: -1rem;
}
.laber_6 .asn{
 margin-left:-6.5rem;
}
.strength {
  width: 62.5rem;
}
.banquan {
  width: 62.5rem;
  max-width: 100%;
  height: 34.58rem;
}
.comment_con{
  font-size: 2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 2.33rem;
  width: 33.56rem;
  height: 4.35rem;
  margin: 0 auto;
  margin-top: 1rem;
}
.banquan > img {
  width: 100%;
  height: 100%;
}
.you{
  width: 39.83rem;
  height: 41rem;
  margin: 0 auto;
  /* margin-top: 10.5rem; */
}
.you>img{
  width: 100%;
  height: 100%;
}
.keyi{
  
  background: rgba(216, 216, 216, 0.18);
  /* height: 51.17rem; */
  padding: 5rem 0;
}
.keyi_2{
  width: 104.5rem;
  display: flex;
  margin: 0 auto;
}
.zuo{
  width: 54.5rem;
  overflow: hidden;
}
.zuo .shijue_comment{
  width: 54.5rem;
  font-size: 2rem;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #2A2E2E;
line-height: 3.33rem;
  margin-top: 5.83rem;
}
.zuo .copyright_title{
  width: 41.67rem;
  height: 7.5rem;
}
.copyright_title img{
  width: 100%;
  height: 100%;
}
.zuo2{
   width: 104.5rem;
   margin: 0 auto;
}
.zuo2 .copyright_title{
  display: flex;
  justify-content: flex-end;
}
.zuo2 .copyright_title .dsakbdj{
  width: 41.67rem;
  height: 7.5rem;
}
</style>